<!--
 * @Description: 完善信息
 * @Author: zhang zhen
 * @Date: 2023-02-09 15:53:55
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-08-12 01:29:18
 * @FilePath: /page-sass/src/views/ImproveInformation.vue
-->
<template>
  <div class="ImproveInformation">
    <a-form-model
      ref="personFrom"
      :colon="false"
      :model="form"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      class="formView"
    >
      <!-- 展示当前身份区域 -->
      <a-row :gutter="64" class="info-line">
        <a-col :span="12">
          <a-form-model-item label="用户类型" class="info-item">
            <a-radio-group v-model="roleType" :options="userTypList" disabled />
          </a-form-model-item>
        </a-col>
        <a-col :span="12" style="visibility: hidden;">
          <!-- 占位防止塌陷 -->
          <a-form-model-item label="用户类型" class="info-item">
            <a-radio-group v-model="roleType" :options="userTypList" disabled />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="用户角色" class="info-item">
            <a-radio-group v-model="tradeIdentity" :options="tradeIdentityList" disabled />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-divider></a-divider>
      <a-row :gutter="64" class="info-line lessMargin">
        <a-col :span="12">
          <a-form-model-item prop="nickname" :rules="{ required: true, message: '请输入真实有效的姓名' }">
            <span slot="label">
              姓名&nbsp;
              <a-tooltip title="请输入真实有效姓名">
                <a-icon type="exclamation-circle" />
              </a-tooltip>
            </span>
            <a-input v-model="form.nickname" placeholder="请输入真实有效的姓名" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="手机号" prop="phone" :rules="{ required: true, validator: validatePhone }">
            <a-input v-model="form.phone" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="邮箱" prop="email" :rules="{ required: true, validator: validateEmail }">
            <a-input v-model="form.email" placeholder="请输入" />
          </a-form-model-item>
        </a-col>
        <template v-if="roleType == 'BUSINESS'">
          <a-col :span="12">
            <a-form-model-item label="公司名称">
              <a-input placeholder="请输入" v-model="form.businessName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="所属集团">
              <a-input placeholder="请输入" v-model="form.department"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="职能信息">
              <j-dictSelect v-model="form.functional" dictCode="1049" placeholder="请选择" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="公司类别">
              <j-dictSelect v-model="form.serviceType" dictCode="1045" placeholder="请选择" />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="行业" :rules="{ required: true, message: '请选择行业' }" prop="industry">
              <j-dictSelect v-model="form.industry" dictCode="1047" placeholder="请选择" />
            </a-form-model-item>
          </a-col>
        </template>
        <a-col :span="12" v-if="tradeIdentity == 0">
          <a-form-model-item
            label="产品类别"
            prop="mainProducts"
            :rules="{ required: true, message: '产品类别不能为空' }"
          >
            <j-dictSelect
              v-model="form.mainProducts"
              dictCode="1052"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12" v-else>
          <a-form-model-item
            label="产品类别"
            prop="mainProducts"
            :rules="{ required: true, message: '产品类别不能为空' }"
          >
            <a-select placeholder="请选择" :getPopupContainer="getPopupContainer" v-model="form.mainProducts">
              <!-- suffixIcon -->
              <a-icon type="caret-down" slot="suffixIcon" style="color: #8C8C8C" />
              <a-select-option :value="undefined">请选择</a-select-option>
              <a-select-option v-for="(item, key) in productTypeList" :key="item.categoryId" :value="item.categoryId">
                <span style="display: inline-block; width: 100%" :title="item.categoryName">
                  {{ item.categoryName }}
                </span>
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" v-if="tradeIdentity != 0">
          <a-form-model-item
            label="年包材需求总额"
            prop="packagingCostTotal"
            :rules="{ required: true, message: '年包材需求总额不能为空' }"
          >
            <j-dictSelect
              v-model="form.packagingCostTotal"
              dictCode="1013"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12" v-else>
          <a-form-model-item
            label="年营业额"
            prop="yearTurnover"
            :rules="{ required: true, message: '年营业额不能为空' }"
          >
            <j-dictSelect
              v-model="form.yearTurnover"
              dictCode="1054"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12">
          <a-form-model-item
            :label="tradeIdentity == 0 ? '单次最小起订量' : '单次最小下单量'"
            prop="minOrderQuantityPerTime"
            :rules="{ required: true, message: tradeIdentity == 0 ? '请选择单次最小起订量' : '请选择单次最小下单量' }"
          >
            <j-dictSelect
              v-model="form.minOrderQuantityPerTime"
              dictCode="1050"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12">
          <a-form-model-item label="设计" prop="design" :rules="{ required: true, message: '请选择设计' }">
            <j-dictSelect
              v-model="form.design"
              dictCode="1015"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12"
          ><a-form-model-item label="打样测试" prop="sampleTest" :rules="{ required: true, message: '请选择打样测试' }">
            <j-multiSelectTag
              dictCode="1051"
              placeholder="请选择"
              v-model="form.sampleTest"
              style="width: 100%;max-width: 320px;"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12">
          <!-- 企业用户 -->
          <a-form-model-item
            v-if="roleType == 'BUSINESS'"
            label="认证要求"
            prop="1004"
            :rules="{ required: true, message: '请选择认证要求' }"
          >
            <j-multiSelectTag
              v-model="form['1004']"
              dictCode="1004"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            />
          </a-form-model-item>
          <!-- 个人用户 -->
          <a-form-model-item
            v-else
            label="认证要求"
            prop="authenticateRequest"
            :rules="{ required: true, message: '请选择认证要求' }"
          >
            <j-multiSelectTag
              v-model="form.authenticateRequest"
              dictCode="1004"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item
            label="其他服务"
            prop="otherService"
            :rules="{ required: true, message: '请选择其他服务' }"
          >
            <j-multiSelectTag
              v-model="form.otherService"
              dictCode="1008"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            />
          </a-form-model-item>
        </a-col>
        <template v-if="tradeIdentity == '0'">
          <a-col :span="12">
            <a-form-model-item
              label="印刷方式:"
              :rules="{ required: true, message: '请选择印刷方式' }"
              prop="printingMethod"
            >
              <j-dictSelect
                v-model="form.printingMethod"
                dictCode="1053"
                placeholder="请选择"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12">
            <a-form-model-item label="成型:" :rules="{ required: true, message: '请选择成型' }" prop="forming">
              <j-dictSelect v-model="form.forming" dictCode="1023" placeholder="请选择成型" /> </a-form-model-item
          ></a-col>
          <a-col :span="12">
            <a-form-model-item
              label="原辅材料:"
              :rules="{ required: true, message: '请输入原辅材料' }"
              prop="primaryMaterials"
            >
              <a-input v-model="form.primaryMaterials" placeholder="请输入" />
            </a-form-model-item>
          </a-col>
        </template>
        <template v-if="tradeIdentity == '2'">
          <a-col :span="12">
            <a-form-model-item
              label="产品包装方式"
              prop="productPackage"
              :rules="{ required: true, message: '请选择产品包装方式' }"
            >
              <j-dictSelect
                v-model="form.productPackage"
                dictCode="1012"
                placeholder="请选择"
                style="width: 100%;max-width: 320px;"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12">
            <!-- 企业采购商 -->
            <a-form-model-item
              label="下单周期"
              v-if="roleType == 'BUSINESS'"
              :rules="{ required: true, message: '请选择下单周期' }"
              prop="1003"
            >
              <j-multiSelectTag
                v-model="form['1003']"
                dictCode="1003"
                placeholder="请选择"
                style="width: 100%;max-width: 320px;"
              />
            </a-form-model-item>
            <!-- 用户采购商 -->
            <a-form-model-item label="下单周期" :rules="{ required: true, message: '请选择下单周期' }" v-else>
              <j-dictSelect
                v-model="form.orderCycle"
                dictCode="1003"
                placeholder="请选择"
                style="width: 100%;max-width: 320px;"
              /> </a-form-model-item
          ></a-col>
          <a-col :span="12">
            <!-- 企业采购商 -->
            <a-form-model-item
              v-if="roleType == 'BUSINESS'"
              label="要货方式"
              prop="1005"
              :rules="{ required: true, message: '请选择要货方式' }"
            >
              <j-multiSelectTag
                v-model="form['1005']"
                dictCode="1005"
                placeholder="请选择"
                style="width: 100%;max-width: 320px;"
              />
            </a-form-model-item>
            <!-- 用户采购商 -->
            <a-form-model-item
              v-else
              label="要货方式"
              prop="purchaseMode"
              :rules="{ required: true, message: '请选择要货方式' }"
            >
              <j-dictSelect
                v-model="form.purchaseMode"
                dictCode="1005"
                placeholder="请选择"
                style="width: 100%;max-width: 320px;"
              /> </a-form-model-item
          ></a-col>
        </template>
        <a-col :span="12">
          <a-form-model-item
            label="供货区域"
            prop="mainSalesAreas"
            :rules="{ required: true, message: '请选择供货区域' }"
          >
            <j-dictSelect
              v-model="form.mainSalesAreas"
              dictCode="1048"
              placeholder="请选择"
              style="width: 100%;max-width: 320px;"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="btn-area">
      <a-button @click="handleNextPage">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleSubmit" style="margin-left: 8px;">提交</a-button>
    </div>
    <!-- 验证码 -->
    <Captcha ref="Captcha" @ok="handleSendMessage" />
  </div>
</template>

<script>
import { postQueryAction, getAction, postAction } from '@/api/manage'
import { mapGetters } from 'vuex'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import Captcha from '@/components/tools/captcha.vue'
import JMultiSelectTag from '@/components/plugins/JMultiSelectTag.vue'
export default {
  name: 'ImproveInformation',
  components: {
    JDictSelect,
    Captcha,
    JMultiSelectTag
  },
  data() {
    return {
      getPopupContainer: node => node.parentNode,
      sendCodeSec: 0,
      activeKey: 'company',
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      companyForm: {},
      form: {},
      productTypeList: [],
      // 贸易身份 （，0-生产商，1-服务商，2-采购商，）
      userTypList: [
        {
          label: '企业',
          value: 'BUSINESS'
        },
        {
          label: '用户',
          value: 'PERSON'
        }
      ],
      // 0-生产商，1-服务商，2-采购商
      tradeIdentityList: [
        {
          label: '采购商',
          value: 2
        },
        {
          label: '生产商',
          value: 0
        }
      ],
      loading: false,
      regionList: [],
      regionIdTwoList: [],
      regionIdThreeList: [],
      roleType: 'BUSINESS',
      tradeIdentity: '0'
    }
  },
  created() {
    this.loadRegionList()
    this.initDict()
    this.handleGetInfo() // 初始化企业信息
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleSubmit() {
      this.roleType == 'BUSINESS' ? this.handleSubmitCompanyInfo() : this.handleSubmitUser()
    },
    initDict() {
      const { tradeIdentity, roleType } = this.setUserInfo()
      this.roleType = roleType
      this.tradeIdentity = tradeIdentity
      getAction('/material/category/queryByType', { categoryType: '1' }).then(res => {
        const { data, success } = res
        success && data && (this.productTypeList = data)
      })
    },
    /* 显示图形验证码 */
    handleShowCaptcha(phone) {
      if (!phone) return this.$message.warning('手机号不能为空')
      this.$refs.Captcha.handleOpenModal()
    },
    handleGetInfo() {
      if (!this.setUserInfo().businessId) return
      postQueryAction('/organizational/selAllBusiness', { id: this.setUserInfo().businessId }).then(res => {
        const { success, data } = res
        if (success) {
          this.companyForm = Object.assign({}, data)
          const { regionId, regionIdTwo } = data
          regionId && this.handleChangeRootId(true)
          regionIdTwo && this.handleLoadCity('regionIdThree', true)
        }
      })
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1
      }).then(res => {
        const { success, data } = res
        if (success) {
          this.regionList = data
        }
      })
    },
    /* 获取用户信息更新 */
    handleLoadUserInfo(callback) {
      let storageInfo = localStorage.getItem('authInfo')
      const { userId } = JSON.parse(storageInfo)
      getAction('/user/info', { userId }).then(res => {
        const { success, data } = res
        if (success) {
          let userInfo = JSON.stringify(data)
          localStorage.setItem('userInfo', userInfo)
          this.$store.commit('setUserInfo', data)
          callback()
        }
      })
    },
    handleChangeRootId(flag = false) {
      this.regionIdTwoList = []
      if (!flag) {
        this.regionIdThreeList = []
        this.companyForm.regionIdTwo = undefined
        this.companyForm.regionIdThree = undefined
      }
      this.handleLoadCity('regionIdTwo', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'regionIdTwo') {
        this.regionIdTwoList = []
        this.regionIdThreeList = []
        !flag && (this.companyForm.regionIdTwo = undefined)
        parentId = this.companyForm.regionId
      }
      if (key == 'regionIdThree') {
        this.regionIdThreeList = []
        !flag && (this.companyForm.regionIdThree = undefined)
        parentId = this.companyForm.regionIdTwo
      }
      getAction('/area/queryByParentIdList', {
        parentId
      }).then(res => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    validatePhone(rule, value, callback) {
      let reg = new RegExp(/^(?:(?:\+|00)86)?1[3-9]\d{9}$/)
      if (!value) {
        callback(new Error('请输入手机号'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    },
    validateEmail(rule, value, callback) {
      let reg = new RegExp(/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/)
      if (!value) {
        callback(new Error('请输入邮箱'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    },
    handleSendMessage(code) {
      let phone = this.activeKey == 'company' ? this.companyForm.phone : this.form.phone
      if (!phone) return this.$message.warning('手机号不能为空')
      postAction('/sms/captcha', {
        phone,
        code
      }).then(res => {
        const { success, message } = res
        if (success) {
          this.$message.success(`验证码已经发送，请查看手机短信`)
          this.sendCodeSec = 60
          this.Timer = setInterval(_ => {
            this.sendCodeSec--
            if (this.sendCodeSec == 0) {
              clearInterval(this.Timer)
            }
          }, 1000)
        } else {
          this.$message.warning(message) // 失败
        }
      })
    },
    handleChangeFormTab(e) {
      this.activeKey = e
      this.form = {}
      this.sendCodeSec = 0
      this.Timer && clearInterval(this.Timer)
      this.loading = false
    },
    handleSubmitCompanyInfo() {
      this.$refs['personFrom'].validate(valid => {
        if (valid) {
          this.loading = true
          const { userId, roleType, tradeIdentity, businessId } = this.setUserInfo()
          let tagsLabelDtos = [],
            sortKeys = []
          // 企业 - 生产商
          if (tradeIdentity == '0') {
            // 1004
            sortKeys = ['1004']
            sortKeys.map(i => {
              if (this.form[i]) {
                let arr = this.form[i].split(',')
                arr.map(item =>
                  tagsLabelDtos.push({
                    businessId: businessId,
                    labelType: i,
                    labelValue: item
                  })
                )
              }
            })
          } else {
            // 企业 - 采购商
            // 1003 1004 1005
            sortKeys = ['1003', '1004', '1005']
            sortKeys.map(i => {
              if (this.form[i]) {
                let arr = this.form[i].split(',')
                arr.map(item =>
                  tagsLabelDtos.push({
                    businessId: businessId,
                    labelType: i,
                    labelValue: item
                  })
                )
              }
            })
          }
          let formData = { ...this.form }
          // if (tradeIdentity != 0 && this.form.mainProducts.length) {
          //   formData.mainProducts = this.form.mainProducts[this.form.mainProducts.length - 1]
          // }
          postAction('/business/inSave', { ...formData, createId: userId, roleType, tradeIdentity, tagsLabelDtos })
            .then(res => {
              const { message, success } = res
              this.loading = false
              if (success) {
                this.$message.success('信息修改成功')
                // this.handleChangeFormTab('person')
                this.handleLoadUserInfo(this.handleNextPage)
              } else {
                this.$message.warning(message)
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    /* 提交用户信息 */
    handleSubmitUser() {
      this.$refs['personFrom'].validate(valid => {
        if (valid) {
          this.loading = true
          const { userId, roleType, tradeIdentity } = this.setUserInfo()
          let formData = { ...this.form }
          // if (tradeIdentity != 0 && this.form.mainProducts.length) {
          //   formData.mainProducts = this.form.mainProducts[this.form.mainProducts.length - 1]
          // }
          postAction('/user/info', { ...formData, userId, roleType, tradeIdentity })
            .then(res => {
              const { message, success } = res
              this.loading = false
              if (success) {
                this.$message('信息修改成功')
                this.handleLoadUserInfo(this.handleNextPage)
              } else {
                this.$message.warning(message)
              }
            })
            .catch(err => {
              this.loading = false
            })
        }
      })
    },
    /* 回到上一页 */
    handleNextPage() {
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
.ImproveInformation {
  // max-width: 1184px;
  // width: calc(100vw - 280px);
  width: 1288px;
  max-width: 1288px;
  padding: 15px;
  box-sizing: border-box;
  padding: 32px 18px 64px 34px;
  background: #ffffff;
  border-radius: 12px;
  margin: 35px auto 40px;
  .info-item {
    margin-bottom: 0 !important;
  }
  ::v-deep.ant-form-item-label > label {
    color: #000;
  }
  .title {
    text-align: center;
  }
  ::v-deep .ant-tabs-nav-scroll {
    display: flex;
    justify-content: center;
  }
  .formView {
    // padding: 0 10px;
    .notice {
      font-size: 12px;
      line-height: 20px;
      color: #e17235;
    }
  }
  span.unit {
    display: inline-block;
    width: 40px;
    height: 32px;
    text-align: center;
    border: 1px solid #d9d9d9;
    line-height: 32px;
  }
  .btn-area {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0 40px;
  }
}
.info-line {
  margin: 0 auto !important;
  width: calc(100% - 64px);
}
.lessMargin {
  ::v-deep.ant-form-item {
    margin-bottom: 14px;
  }
}
</style>
